"use strict"
import {observable, action} from "mobx"
import api from "../../global/actions/api"
import GlobalStore from "../../global/store/GlobalStore"

class StornoRequestDialogStore {
	@observable isOpen = false
	@observable recordId = null
	@observable reason = ""

	@action open(id) {
		this.isOpen = true
		this.recordId = id
	}

	@action onHandleChange(value) {
		this.reason = value
	}

	@action storno(callback) {
		const reason = {
			reason: this.reason
		}

		api
			.deleteSolverRequests(this.recordId, reason)
			.call()
			.then(() => {
				this.close()
				GlobalStore.setNotificationMessage("Žiadosť úspešne odstránená")
        callback()
			})
			.catch(() => {
				GlobalStore.setNotificationMessage("Nastala chyba", null, "warning")
			})
	}

	@action close() {
		this.isOpen = false
		this.recordId = null
		this.reason = ""
	}
}

var singleton = new StornoRequestDialogStore()
export default singleton
