import {observable, action} from "mobx"
import api from "../../global/actions/api"
// import {getUserCompanyInfo} from "../../global/helpers/actions"
// import {getFilters} from "../../global/helpers/api"
import {printReport} from "../../global/helpers/actions"
import moment from "moment"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"
// import GlobalStore from "../../global/store/GlobalStore"
// import RouterStore from "../../global/store/RouterStore"

class SolverRequestsStore {
	@observable listData = []
	filterFormRef = null

	@action async loadCodeListsAndGetRequests(form) {
		await RegistersCacheStore.checkRegisters("/request")
		this.loadListData(form)
	}

	@action loadListData(form) {
		let formVals = null
		let filter = {filters: []}

		if (isSafe(form)) {
			formVals = form.values()
		}

		if (isSafe(formVals)) {
			if (isNotEmpty(formVals.company_name)) {
				filter.filters.push({
					associated_column: "company_name",
					values: [
						{
							id_value: formVals.company_name
						}
					]
				})
			}

			if (isNotEmpty(formVals.identifier)) {
				filter.filters.push({
					associated_column: "identifier",
					values: [
						{
							id_value: formVals.identifier
						}
					]
				})
			}

			if (isNotEmpty(formVals.contact)) {
				filter.filters.push({
					associated_column: "person_name",
					values: [
						{
							id_value: formVals.contact
						}
					]
				})
			}

			if (isNotEmpty(formVals.request_status)) {
				filter.filters.push({
					associated_column: "request_status",
					values: [
						{
							id_value: formVals.request_status
						}
					]
				})
			}

			if (isNotEmpty(formVals.date_from)) {
				filter.time_from = moment(formVals.date_from)
					.startOf("day")
					.utc()
			}

			if (isNotEmpty(formVals.date_to)) {
				filter.time_to = moment(formVals.date_to)
					.endOf("day")
					.utc()
			}
		}

		api
			.loadSolverRequests(filter)
			.call()
			.then((response) => {
				this.listData = response.rows
			})
	}

	@action exportList() {
		let rows = ""
		let filteredRows = this.listData.filter((row) => row.status == "appr")

		if (isSafe(filteredRows)) {
			filteredRows = filteredRows.sort((a, b) => {
				const nameA = a.company_data.name
				const nameB = b.company_data.name
				return nameA.localeCompare(nameB)
			})
			filteredRows.forEach((row, index) => {
				const company_data = row.company_data || {}
				const company_address = company_data.oa_address || {}
				const contact = row.request_contact_data || {}

				if (row.status == "appr") {
					rows += `
				<tr>
					<td style="width: 3%;">
						<p>${index + 1}.</p>
					</td>
					<td style="width: 15%;">
						<p>${row.company_data.name}</p>
					</td>
					<td style="width: 15%;">
						<p>${company_address.full_address}</p>
					</td>
					<td style="width: 15%;">
						<p>${contact.name}, ${contact.phone}, ${contact.email}</p>
					</td>
					<td style="width: 5%;">
						<p>${row.company_data.identifier}</p>
					</td>
					<td style="width: 5%;">
						<p>${row.company_data.tax_identification_number}</p>
					</td>
					<td style="width: 5%;">
						<p>${row.company_data.vat_identification_number}</p>
					</td>
					<td style="width: 35%;">
						<p>${row.orsr_activity_subject}</p>
					</td>
				</tr>	
					`
					// 	<td style="width: 12%;">
					// 	<p>Áno</p>
					// </td>
				}
			})
		}

		let printValues = {table_rows: rows}
		printReport("solverRequests", printValues, null, true, "-L")
	}
}

var singleton = new SolverRequestsStore()
export default singleton
