import {observable, action} from "mobx"
import moment from "moment"
import api from "../../global/actions/api"
// import {getUserCompanyInfo} from "../../global/helpers/actions"
import GlobalStore from "../../global/store/GlobalStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"
import RouterStore from "../../global/store/RouterStore"

class SolverRequestsDetailStore {
	@observable data = {}

	@action loadCallInfo(callId) {
		api
			.loadCalls()
			.call()
			.then((response) => {
				this.data = response.rows.find((row) => row.ID == callId)
			})
	}

	@action async loadCodelistAndForm(form, id) {
		await RegistersCacheStore.checkRegisters("/request")
		this.loadForm(form, id)
	}

	@action loadForm(form, id) {
		GlobalStore.loading(true)
		api
			.loadRequestWithAtt(id)
			.call()
			.then((response) => {
				form.reset()
				const company = response.company_data
				// const orgunit = response.company.specific_rels.find((row) => row._type == "Entity.Data.OrgUnit")
				const addresses = company.oa_address
				const contactPreson = response.request_contact_data
				let formObj = {
					request_id: id,
					company_id: company.company,
					status: response.status._id,
					ico: company.identifier,
					dic: company.tax_identification_number,
					icdph: company.vat_identification_number,
					companyName: company.name,
					companyPSC: addresses.zip_plain,
					companyStreet: isSafe(addresses.street_plain) ? addresses.street_plain : "",
					companyStreetNumber: isSafe(addresses.house_number) ? addresses.house_number : "",
					companyRegion: isSafe(addresses.region) ? addresses.region : "",
					companyCounty: isSafe(addresses.county) ? addresses.county : "",
					companyCity: isSafe(addresses.obj_city) ? addresses.obj_city : "",
					applicantCountry: isSafe(response.country) ? response.country._id : "",
					projectAcronym: response.project_acronym,
					contactPersonId: contactPreson.person,
					contactPersonName: contactPreson.name,
					contactPersonPhone: contactPreson.phone,
					contactPersonMail: contactPreson.email,
					webAddress: response.web_address,
					companyRegistryDate: response.orsr_registration_date,
					companyTypeInRegistry: response.orsr_activity_subject
				}

				form.set(formObj)

				// if (form.$("status").value == "sent") {
				form.$("companyRegistryDate").value = true
				form.$("conflict1").value = true
				form.$("conflict2").value = true
				// }

				GlobalStore.loading(false)
			})
	}

	@action saveForm(form, version, callback) {
		GlobalStore.loading(true)
		const formVals = form.values()

		let req = {
			record_id: isNotEmpty(formVals.request_id) ? formVals.request_id : null,
			_type: "INN.API.JSON.Proxy.SolverRequest",
			version: isNotEmpty(version) ? version : "draft",
			status: isNotEmpty(formVals.status)
				? formVals.status
				: isNotEmpty(version) && version == "final"
				? "sent"
				: "inp",
			type_id: "INN.Data.SolverRequest",
			attached_files: [],
			company_data: {
				company: formVals.company_id,
				identifier: formVals.ico,
				name: formVals.companyName,
				tax_identification_number: formVals.dic,
				vat_identification_number: formVals.icdph,
				oa_address: {
					country: {
						_type: "Entity.CL.Country",
						_id: "SK"
					},
					house_number: formVals.companyStreetNumber,
					obj_city: {
						_type: "Entity.CL.Address.City",
						_id: formVals.companyCity
					},
					primary: true,
					street_plain: formVals.companyStreet,
					type: {
						_type: "Entity.CL.AddressType",
						_id: "OA"
					},
					zip_plain: formVals.companyPSC
				}
			},
			request_contact_data: {
				person: isNotEmpty(formVals.contactPersonId) ? formVals.contactPersonId : null,
				name: isNotEmpty(formVals.contactPersonName) ? formVals.contactPersonName : null,
				email: isNotEmpty(formVals.contactPersonMail) ? formVals.contactPersonMail : null,
				phone: isNotEmpty(formVals.contactPersonPhone) ? formVals.contactPersonPhone : null
			},
			web_address: formVals.webAddress,
			orsr_registration_date: isNotEmpty(formVals.companyRegistryDate)
				? moment(formVals.companyRegistryDate).format("YYYY-MM-DD")
				: null,
			orsr_activity_subject: formVals.companyTypeInRegistry
		}

		api
			.saveRequest(req)
			.call()
			.then((response) => {
				GlobalStore.setNotificationMessage("Record saved successfully", "", "success")
				if (isSafe(callback)) {
					callback(response._id)
				}
			})
	}

	@action acceptRequest(id) {
		const reason = {
			status_id: "appr"
		}

		api
			.changeSolverRequestStatus(id, reason)
			.call()
			.then(() => {
				RouterStore.push("/solverRequests")
				GlobalStore.setNotificationMessage("Žiadosť schválená", "", "success")
			})
	}
}

var singleton = new SolverRequestsDetailStore()
export default singleton
