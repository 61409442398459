import React from "react"
import {observer} from "mobx-react"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import XsButton from "../../global/ui/xsButton/xsButton"
import CloseRegionCallDialogStore from "./closeRegionCallDialogStore"
import {Grid} from "@material-ui/core"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"
import XsDateTimePicker from "../../global/ui/xsDateTimePicker/xsDateTimePicker"
import GlobalStore from "../../global/store/GlobalStore"
import XsConfirmationDialog from "../../global/ui/xsDialog/xsConfirmationDialog"

@observer
export default class CloseRegionCallDialog extends React.Component {
	constructor(props) {
		super(props)

		const fields = {
			fields: {
				dateFrom: {
					label: "Platnosť od",
					type: "date",
					rules: "required"
				},
				dateTo: {
					label: "Platnosť do",
					type: "date",
					rules: "required"
				},
				region: {},
				regionId: {}
			}
		}

		const hooks = {
			onSubmit() {},
			onSuccess(form) {
				logger("SAVE", form.values())
				GlobalStore.openConfirmationDialog("xsCloseCalRegionlSureDialog")
			},
			onError(form) {
				logger("CHYBY:", form.errors())
				GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "", "warning")
			}
		}

		this.form = new MobxReactForm(fields, {plugins: {dvr: dvr(validatorjs)}, hooks})
	}

	componentDidMount() {
		if (isSafe(CloseRegionCallDialogStore.defaultData)) {
			this.form.$("region").value = CloseRegionCallDialogStore.defaultData.NameExt
			this.form.$("regionId").value = CloseRegionCallDialogStore.defaultData.Code
			this.form.$("dateFrom").value = isNotEmpty(CloseRegionCallDialogStore.defaultData.ValidityFrom)
				? CloseRegionCallDialogStore.defaultData.ValidityFrom
				: ""
			this.form.$("dateTo").value = isNotEmpty(CloseRegionCallDialogStore.defaultData.ValidityTo)
				? CloseRegionCallDialogStore.defaultData.ValidityTo
				: ""
		}
	}

	render() {
		return (
			<Dialog
				id="xsCloseRegionCallDialog"
				open={CloseRegionCallDialogStore.isOpen}
				onClose={() => CloseRegionCallDialogStore.close(this.form)}
				disableBackdropClick={true}
				maxWidth="sm"
				fullWidth
				className="xs-base-dialog"
			>
				<DialogTitle className="xs-danger bgAmberWarning white " style={{textAlign: "center"}}>
					<div className="xs-header">
						<div className="xs-header-icon">
							<i className="fal fa fa-calendar fa-2x" />
						</div>
						<div className="xs-header-title">Zmena platnosti kraja</div>
					</div>
				</DialogTitle>
				<DialogContent className="xs-content">
					<Grid container spacing={2} className="pt-4">
						<Grid item xs={12}>
							Región: {this.form.$("region").value}
						</Grid>
						<Grid item xs={12}>
							<XsDateTimePicker field={this.form.$("dateFrom")} showTime />
						</Grid>
						<Grid item xs={12}>
							<XsDateTimePicker field={this.form.$("dateTo")} showTime />
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className="xs-footer xs-space-between">
					<XsButton
						className="xs-default action-close"
						icon={<i className="fal fa-times" />}
						text={"Zrušiť"}
						onClick={() => CloseRegionCallDialogStore.close(this.form)}
					/>
					<XsButton
						className="xs-default action-close"
						icon={<i className="fal fa-calendar" />}
						text={"Zmeniť"}
						onClick={(e) => this.form.onSubmit(e)}
					/>
				</DialogActions>

				<XsConfirmationDialog
					name="xsCloseCalRegionlSureDialog"
					text="Naozaj chcete zmeniť platnosť kraja?"
					confirmBtn={{
						icon: <i className="fal fa-check" />,
						text: "Common.btn.yes"
					}}
					cancelBtn={{
						icon: <i className="fal fa-times" />,
						text: "Common.btn.no"
					}}
					onConfirmation={() => {
						CloseRegionCallDialogStore.closeRegionCall(this.form, this.props.id)
					}}
				/>
			</Dialog>
		)
	}
}

