"use strict"

import React, {useEffect, useState} from "react"
import {observer} from "mobx-react"
import {injectIntl, FormattedMessage} from "react-intl"
import MobxReactForm from "mobx-react-form"
import validatorjs from "validatorjs"
import dvr from "mobx-react-form/lib/validators/DVR"

import {Grid, Paper, Container, Typography, Link, Box} from "@material-ui/core"

import XsInput from "../../global/ui/xsInput/xsInput"
import XsButton from "../../global/ui/xsButton/xsButton"

import {isPasswordValid} from "../../global/helpers/functions"

import GlobalStore from "../../global/store/GlobalStore"
import RouterStore from "../../global/store/RouterStore"
import LoginStore from "./loginStore"

function ResetPasswordContainer() {
	const hooks = {
		onSubmit(form) {
			const {password, conf_psw} = form.values()

			if (isNotEmpty(password) && password != conf_psw) {
				GlobalStore.setNotificationMessage("Heslo a potvrdenie hesla sa musia zhodovať!", "", "warning")
				form.$("password").invalidate()
			} else if (!isPasswordValid(password) || !isPasswordValid(conf_psw)) {
				GlobalStore.setNotificationMessage(
					"The password must contain at least one uppercase letter, one lowercase letter and one number. Minimum length of password is 8 characters.",
					"",
					"warning"
				)
				form.$("password").invalidate()
			}
		},
		onSuccess(form) {
			LoginStore.resetPasswordFinish(form)
		},
		onError() {
			GlobalStore.setNotificationMessage("Skontrolujte povinné polia!", "", "warning")
		}
	}

	const [form] = useState(
		new MobxReactForm(
			{
				fields: {
					password: {
						label: "Nové heslo"
					},
					conf_psw: {
						label: "Potvrdenie hesla"
					}
				}
			},
			{plugins: {dvr: dvr(validatorjs)}, hooks}
		)
	)

	useEffect(() => {
		LoginStore.token = new URLSearchParams(RouterStore.location.search).get("token")
	}, [])

	return (
		<React.Fragment>
			<div className="main_header">
				<Container maxWidth="lg">
					<Grid container alignItems="center" justify="space-between" className="pt-2 pb-2">
						<Grid item>
							<img
								src="/public/images/logo_inovujme_backoffice.png"
								style={{width: "100px", height: "100px"}}
								className="pointer"
								onClick={() => RouterStore.push("/login")}
							/>
						</Grid>
						<Grid item class="header_gradient">
							<Typography variant="h3">
								<FormattedMessage id="Common.label.resetPsw" />
							</Typography>
						</Grid>
						<Grid item style={{width: 100}}></Grid>
					</Grid>
				</Container>
			</div>
			<Container maxWidth="lg">
				<Grid container justify="center">
					<Grid item style={{maxWidth: "400px", width: "400px"}}>
						<Grid container direction="column" justify="center" style={{height: "65vh"}}>
							<Paper variant="elevation" elevation={2} style={{backgroundColor: "transparent"}}>
								<Box p={2}>
									<Grid item container direction="column" spacing={2}>
										<Grid item>
											<Typography variant="subtitle2">
												Heslo musí obsahovaťveľké aj malé písmená a aspoň jedno číslo. Dĺžka hesla musí byť minimálne 8
												znakov.
											</Typography>
										</Grid>
										<Grid item>
											<XsInput
												regex={(val) => isPasswordValid(val)}
												type="password"
												field={form.$("password")}
												autoFocus={true}
												submitAction={(e) => form.onSubmit(e)}
											/>
										</Grid>
										<Grid item>
											<XsInput
												regex={(val) => isPasswordValid(val)}
												type="password"
												field={form.$("conf_psw")}
												submitAction={(e) => form.onSubmit(e)}
											/>
										</Grid>
										<Grid item>
											<XsButton text={<FormattedMessage id="Common.label.resetPsw" />} onClick={form.onSubmit} />
										</Grid>
										<Grid item>
											<Link
												variant="body2"
												className="pointer"
												onClick={() => {
													LoginStore.screen = LoginStore.screens.LOGIN
													RouterStore.push("./login")
												}}
											>
												Prihlásiť sa
											</Link>
										</Grid>
									</Grid>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</React.Fragment>
	)
}

export default injectIntl(observer(ResetPasswordContainer))
