"use strict"
import {observable, action} from "mobx"
import api from "../../global/actions/api"
import CallDetailStore from "./callDetailStore"

class closeRegionCallDialogStore {
	@observable isOpen = false
	@observable defaultData = null

	@action open(defaultData) {
		this.isOpen = true
		this.defaultData = defaultData
	}

	@action closeRegionCall(form, callId) {
		const formVals = form.values()
		const req = {
			call_id: callId,
			region_id: formVals.regionId,
			validity_from: formVals.dateFrom,
			validity_to: formVals.dateTo
		}

		api
			.setRegionRestriction(req)
			.call()
			.then(() => {
				this.close(form)
				CallDetailStore.checkRegionAvailability(callId)
			})
	}

	@action close(form) {
		this.isOpen = false
		this.defaultData = null
		if (isSafe(form)) {
			form.reset()
		}
	}
}

var singleton = new closeRegionCallDialogStore()
export default singleton
