"use strict"
import {observable, action} from "mobx"
import api from "../../global/actions/api"
import RouterStore from "../../global/store/RouterStore"

class CloseCallDialogStore {
	@observable isOpen = false
	@observable defaultData = null

	@action open(defaultData) {
		this.defaultData = defaultData
		this.isOpen = true
	}

	@action closeCall(form, id) {
		const formVals = form.values()
		const req = {
			validity_from: formVals.dateFrom,
			validity_to: formVals.dateTo
		}

		api
			.editCallPut(id, req)
			.call()
			.then(() => {
				this.close(form)
				RouterStore.push(`/calls`)
			})
	}

	@action close(form) {
		this.isOpen = false
		this.defaultData = null

		if (isSafe(form)) {
			form.reset()
		}
	}
}

var singleton = new CloseCallDialogStore()
export default singleton

