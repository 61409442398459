"use strict"

import React from "react"
import {observer} from "mobx-react"
import classnames from "classnames"
import {useTable, useSortBy, usePagination, useResizeColumns, useFlexLayout, useGroupBy, useExpanded} from "react-table"
import {Grid, Box, Table, TableBody, TableCell, TableHead, TableRow, MenuItem, TextField} from "@material-ui/core"
import XsIconButton from "../xsButton/xsIconButton"
import {injectIntl} from "react-intl"
import "./xsTable.less"

const RTable = ({columns, data, onRowClick, colorful, size, defaultSort, groupByCol, noPagination}) => {
	const filterTypes = React.useMemo(
		() => ({
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id]
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true
				})
			}
		}),
		[]
	)

	const defaultColumn = React.useMemo(
		() => ({
			minWidth: 50,
			width: 150
			// maxWidth: 400,
		}),
		[]
	)

	let initialState = {
		pageIndex: 0,
		pageSize: noPagination ? 10000 : 10,
		groupBy: [groupByCol]
	}

	if (isSafe(defaultSort) && isSafe(defaultSort.id)) {
		initialState["sortBy"] = defaultSort
	}

	const {
		getTableProps,
		getTableBodyProps,
		// footerGroups,
		headerGroups,
		rows,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		// pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		// setGroupBy,
		previousPage,
		setPageSize,
		state: {pageIndex, pageSize}
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			initialState: initialState,
			showPagination: false
		},
		useResizeColumns,
		useFlexLayout,
		useGroupBy,
		useSortBy,
		useExpanded,
		usePagination
	)

	const isRowClick = isSafe(onRowClick) && typeof onRowClick === "function" ? true : false
	return (
		<React.Fragment>
			<Table {...getTableProps()} className="r-table">
				<TableHead>
					{headerGroups.map((headerGroup, idx) => (
						<TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, i) => {
								// const {isResizing, getResizerProps} = column
								return (
									<TableCell
										key={`${idx}_${i}`}
										{...column.getHeaderProps(column.getSortByToggleProps())}
										className={classnames("th", {small: size == "small"})}
										// className={`td resizer ${isResizing ? "isResizing" : ""}`}
									>
										{/* {column.canGroupBy ? (
											<span {...column.getGroupByToggleProps()}>{column.isGrouped ? "🛑 " : "👊 "}</span>
										) : null} */}
										{column.render("Header")}
										<span>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<span>
														{" "}
														<i className="fal fa-long-arrow-down"></i>
													</span>
												) : (
													<span>
														{" "}
														<i className="fal fa-long-arrow-up"></i>
													</span>
												)
											) : (
												""
											)}
										</span>
										{/* <div {...getResizerProps()} className={`resizer ${isResizing ? "isResizing" : ""}`} /> */}
									</TableCell>
								)
							})}
						</TableRow>
					))}
				</TableHead>
				<TableBody {...getTableBodyProps()}>
					{page.map((row, idx) => {
						prepareRow(row)
						return (
							<TableRow
								key={idx}
								{...row.getRowProps()}
								className={classnames({bgWhite: isEmpty(groupByCol) && colorful && idx % 2 == 1})}
							>
								{row.cells.map((cell, i) => {
									const cellClick = isRowClick && isNotSafe(cell.column.clickable)

									return (
										<TableCell
											key={`${idx}_${i}`}
											onClick={() => (cellClick ? onRowClick(row.original) : null)}
											{...cell.getCellProps()}
											// style={{background: cell.isGrouped ? "#0aff0082" : "white"}}
											className={classnames("td", {
												pointer: cellClick,
												small: size == "small",
												bgWhite: cell.isAggregated || cell.isGrouped
											})}
											// style={{padding: padding}}
										>
											{cell.isGrouped ? (
												<React.Fragment>
													<span {...row.getToggleRowExpandedProps()}>
														{row.isExpanded ? (
															<i className="fas fa-caret-down fa-lg pr-1"></i>
														) : (
															<i className="fas fa-caret-right fa-lg pr-1"></i>
														)}
													</span>{" "}
													{cell.render("Cell")} ({row.subRows.length})
												</React.Fragment>
											) : cell.isAggregated ? (
												cell.render("Aggregated")
											) : cell.isPlaceholder ? null : (
												cell.render("Cell")
											)}
										</TableCell>
									)
								})}
							</TableRow>
						)
					})}
				</TableBody>
			</Table>
			{!noPagination && (
				<Box pt={1}>
					<Grid container spacing={2} alignItems="center" justify="flex-end">
						{/* <Grid item>Počet záznamov na stranu:</Grid> */}
						<Grid item>Počet záznamov na stranu:</Grid>
						<Grid item>
							<TextField size="small" select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
								<MenuItem key={5} value={5}>
									5
								</MenuItem>
								<MenuItem key={10} value={10}>
									10
								</MenuItem>
								<MenuItem key={20} value={20}>
									20
								</MenuItem>
								<MenuItem key={50} value={50}>
									50
								</MenuItem>
							</TextField>
						</Grid>
						<Grid item>
							{pageIndex * pageSize + 1} -{" "}
							{pageIndex * pageSize + pageSize > rows.length ? rows.length : pageIndex * pageSize + pageSize} z{" "}
							{rows.length}
						</Grid>
						<Grid item>
							<XsIconButton
								size="large"
								variant="text"
								icon={<i className="far fa-chevron-double-left" />}
								onClick={() => gotoPage(0)}
								disabled={!canPreviousPage}
								color="default"
							/>
						</Grid>
						<Grid item>
							<XsIconButton
								size="large"
								variant="text"
								icon={<i className="far fa-chevron-left" />}
								onClick={() => previousPage()}
								disabled={!canPreviousPage}
								color="default"
							/>
						</Grid>
						<Grid item>
							<XsIconButton
								size="large"
								variant="text"
								icon={<i className="far fa-chevron-right" />}
								onClick={() => nextPage()}
								disabled={!canNextPage}
								color="default"
							/>
						</Grid>
						<Grid item>
							<XsIconButton
								size="large"
								variant="text"
								icon={<i className="far fa-chevron-double-right" />}
								onClick={() => gotoPage(pageCount - 1)}
								disabled={!canNextPage}
								color="default"
							/>
						</Grid>
						{/* <Grid item>| Go to page:</Grid>
				<Grid item>
					<TextField
						type="number"
						variant="outlined"
						defaultValue={pageIndex + 1}
						size="small"
						onChange={(e) => {
							const page = e.target.value ? Number(e.target.value) - 1 : 0
							gotoPage(page)
						}}
					/>
				</Grid> */}
					</Grid>
				</Box>
			)}
		</React.Fragment>
	)
}

function xsTable({columns, data, onRowClick, size, colorful, defaultSort, groupBy, noPagination}) {
	return (
		<RTable
			columns={columns}
			data={data}
			onRowClick={isSafe(onRowClick) ? (row) => onRowClick(row) : null}
			colorful={colorful}
			groupByCol={groupBy}
			defaultSort={defaultSort}
			size={size}
			noPagination={noPagination}
		/>
	)
}

export default injectIntl(observer(xsTable))

