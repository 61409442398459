"use strict"

import {observable, action} from "mobx"
// import {getFilters} from "../../global/helpers/api"
// import {getUserCompanyInfo} from "../../global/helpers/actions"
import api from "../../global/actions/api"
import GlobalStore from "../../global/store/GlobalStore"
import moment from "moment"
import RequestFormStore from "../RequestForm/RequestFormStore"
import RequestPrintStore from "../RequestForm/RequestPrintStore"
import RegistersCacheStore from "../../global/store/RegistersCacheStore"
import {exportToExcel} from "../../global/helpers/actions"

class RequestStore {
	@observable data = []
	@observable callItems = []

	filterFormRef = null

	@action getCallItems() {
		api
			.loadCallItems()
			.call()
			.then((response) => {
				this.callItems = response.rows
			})
	}

	@action async loadCodeListsAndGetRequests(form) {
		GlobalStore.loading(true, "Načítavam...")
		await RegistersCacheStore.checkRegisters("/request")
		this.getRequests(form)
	}

	generateFiltersForList(form) {
		const formVals = form.values()
		let req = {filters: []}

		if (isNotEmpty(formVals.dateFrom)) {
			req["time_from"] = moment(formVals.dateFrom).utc()
			// req["filters"].push({
			// 	associated_column: "time_from",
			// 	predicate: "=",
			// 	values: [{id_value: moment(formVals.dateFrom).utc()}]
			// })
		}
		if (isNotEmpty(formVals.dateTo)) {
			req["time_to"] = moment(formVals.dateTo).utc()
			// req["filters"].push({
			// 	associated_column: "time_to",
			// 	predicate: "=",
			// 	values: [{id_value: moment(formVals.dateTo).utc()}]
			// })
		}
		if (isNotEmpty(formVals.callCode)) {
			// req["call_id"] = formVals.callCode
			req["filters"].push({
				associated_column: "call_id",
				predicate: "=",
				values: [{id_value: formVals.callCode}]
			})
		}

		if (isNotEmpty(formVals.callStatus)) {
			req["filters"].push({
				associated_column: "request_status",
				predicate: "=",
				values: [{id_value: formVals.callStatus}]
			})
		}

		if (isNotEmpty(formVals.ico)) {
			// req["identifier"] = formVals.ico
			req["filters"].push({
				associated_column: "identifier",
				predicate: "=",
				values: [{id_value: formVals.ico}]
			})
		}
		if (isNotEmpty(formVals.zoipNumber)) {
			// req["request_id"] = formVals.zoipNumber
			req["filters"].push({
				associated_column: "request_id",
				predicate: "=",
				values: [{id_value: formVals.zoipNumber}]
			})
		}

		return req
	}

	@action getRequests(form) {
		GlobalStore.loading(true, "Načítavam...")

		let req = this.generateFiltersForList(form)
		// let req = getFilters([`company_id=${getUserCompanyInfo().id}`])
		// req["filters"].push({
		// 	associated_column: "type_id",
		// 	predicate: "=",
		// 	values: [
		// 		{id_value: "INN.Data.InnovationRequest"},
		// 		{id_value: "INN.Data.Request.IPSK121"},
		// 		{id_value: "INN.Data.Request.IPZS122"},
		// 		{id_value: "INN.Data.Request.IPZS2123"},
		// 		{id_value: "INN.Data.Request.IPSK2122"},
		// 		{id_value: "INN.Data.Request.IPSK3122"},
		// 		{id_value: "INN.Data.Request.ZSK123"}
		// 	]
		// })
		req["orders"] = {associated_column: "sent_date_time", asc: true}

		api
			.getRequests(req)
			.call()
			.then((res) => {
				if (isSafe(res) && isSafe(res.rows)) {
					this.data = res.rows
				} else {
					this.data = []
				}

				GlobalStore.loading(false)
			})
	}

	@action generatePDF(form, id) {
		const afterLoad = () => {
			RequestPrintStore.print(form)
			form.reset()
			RequestFormStore.resetVals()
		}
		logger(form, id)
		RequestFormStore.load(form, id, afterLoad)
	}

	@action generateExcel(form, id) {
		const afterLoad = () => {
			RequestPrintStore.generateExcel(form)
			form.reset()
			RequestFormStore.resetVals()
		}
		logger(form, id)
		RequestFormStore.load(form, id, afterLoad)
	}

	downloadAttachmentsForRow(row) {
		let atts = row.attachments
		if (isSafe(atts)) {
			const att = atts.find((row) => row.active == true)
			if (isSafe(att)) {
				const fileType = att.file_name.split(".").pop()
				let fileName = ""
				if (isNotEmpty(row.innovation_request_id) && row.innovation_request_id.includes("ZoIP")) {
					fileName = row.innovation_request_id.replace("ZoIP", "CP")
				} else {
					fileName = `CP_${row.innovation_request_id}`
				}
				fileName += `.${fileType}`
				api.downloadFile(att.api_url, fileName)
			}
		}
	}

	@action exportListToXls(form, exportDescription = false) {
		GlobalStore.loading(true, "Exportujem...")

		let filters = this.generateFiltersForList(form)

		let req = {
			uri: "/inn/record/export-overview-int?level_flag=CDL4",
			filters: filters.filters
		}

		if (isSafe(filters.time_from)) {
			req["time_from"] = filters.time_from
		}

		if (isSafe(filters.time_to)) {
			req["time_to"] = filters.time_to
		}

		if (exportDescription) {
			filters["filters"].push({
				associated_column: "export_project_description",
				predicate: "=",
				values: [{id_value: true}]
			})
		}

		exportToExcel(req)
	}
}

var singleton = new RequestStore()
export default singleton

