// import {FormattedMessage} from "react-intl"
// import React from "react"
// import {/*getPerson, */ getUser} from "../../global/helpers/actions"
// import moment from "moment"

export default {
	load() {
		return {
			fields: {
				status: {},
				version: {},
				request_id: {},
				ico: {
					label: "IČO",
					rules: "required|between:8,8"
				},
				dic: {
					label: "DIČ",
					rules: "required|between:10,10"
				},
				icdph: {
					label: "IČ DPH",
					rules: "required"
				},
				companyName: {
					label: "Obchodné meno",
					rules: "required"
				},
				companyStreet: {
					label: "Ulica",
					rules: "required"
				},
				companyStreetNumber: {
					label: " Popisné číslo",
					rules: "required"
				},
				companyCity: {
					label: "Obec",
					rules: "required"
				},
				companyPSC: {
					label: "PSČ",
					rules: "required|between:5,5"
				},
				contactPersonName: {
					label: "Titul, Meno, Priezvisko, Titul",
					rules: "required"
				},
				contactPersonPhone: {
					label: "Telefónne číslo",
					rules: "required"
				},
				contactPersonMail: {
					label: "Email",
					rules: "email|required"
				},
				contactPersonId: {},
				webAddress: {
					label: "Webové sídlo žiadateľa",
					rules: "required"
				},
				companyRegistryDate: {
					label:
						"Vyhlasujem/e, že od dátumu registrácie v ORSR/ŽRSR ubehlo najmenej 12 mesiacov (ak je nesplnená táto podmienka, ukončí sa registrácia oznámením o nesplnení vylučovacej podmienky)",
					rules: "required",
					type: "checkbox"
				},
				companyTypeInRegistry: {
					label:
						"Predmety činností s dátumami podľa zápisu v obchodnom, živnostenskom, resp. inom registri, ktoré plánuje riešiteľ realizovať v rámci inovačných projektov",
					rules: "required"
				},
				conflict1: {
					label:
						"Vyhlasujem, že poznám definíciu konfliktu záujmov, podľa ktorej pojem konfliktu záujmov zahŕňa prinajmenšom každú situáciu, keď osoby na strane obstarávateľa alebo poskytovateľa obstarávacích služieb konajúceho v mene obstarávateľa, ktorí sú zapojení do vykonávania postupu obstarávania alebo môžu ovplyvniť výsledok tohto postupu (bez nutnosti ich zapojenia), majú priamo alebo nepriamo finančný, ekonomický alebo iný osobný záujem, ktorý možno vnímať ako ohrozenie ich nestrannosti a nezávislosti v súvislosti s daným postupom verejného obstarávania.",
					rules: "required",
					type: "checkbox"
				},
				conflict2: {
					label:
						"Som si vedomý/á so skutočnosťou, že v prípade, ak poskytovateľ alebo iný kontrolný a auditný orgán zistí v predmetnom verejnom obstarávaní konflikt záujmov, uvedené zistenie môže mať vplyv na oprávnenosť výdavkov a následné vylúčenie výdavkov súvisiacich s verejným obstarávaním z financovania v plnom rozsahu.",
					rules: "required",
					type: "checkbox"
				},
				company_id: {}
			}
		}
	}
}
