import {useState, useEffect, useRef} from "react"

import {StorageBase} from "../storage/storageEx"
import RouterStore from "../store/RouterStore"
import GlobalStore from "../store/GlobalStore"

import {USER_DATA} from "../config/constants"
import config from "../config/settings"
import {load} from "recaptcha-v3"
import api from "../actions/api"
import {ApiCall} from "./api"

export const getUser = () => StorageBase.getObjectByKey(USER_DATA)

export const login = (username, password) => {
	GlobalStore.loading(true, "Login in progress...")
	try {
		load(config.SITE_KEY).then((recaptcha) => {
			recaptcha.execute("security_login").then((recaptchaToken) => {
				api
					.login(recaptchaToken, username, password)
					.call((statusCode) => {
						if (statusCode != 200) {
							GlobalStore.authentificationFailed = true
						}
					})
					.then((user) => {
						if (typeof user == "undefined") {
							GlobalStore.authentificationFailed = true
							throw new Error()
						}

						const days40 = 40 * 24 * 60 * 60 * 1000
						StorageBase.deleteByValueQuery((x) => isSafe(x.info) && x.info.LastModified < Date.now() - days40)

						StorageBase.updateByKey(USER_DATA, user)

						GlobalStore.loading(false)

						RouterStore.push("/calls")
					})
			})
		})
	} catch (err) {
		logger(err)
	}
}

//Zavolá mPDF API a pošle HTML alebo názov template
export const printReport = (templateName = null, values, directHtml, streamCallback, format = null) => {
	const user = getUser() || {}

	const requestData = {
		_html: directHtml,
		_template: templateName,
		_format: format,
		_stream: streamCallback ? true : false,
		_token: `SIEA ${user.sieatoken || ""}`,
		_apiBaseUri: config.API_BASE_URL,
		_paddingTop: 0
	}

	printMPDF(streamCallback, requestData, values)
}

export const printMPDF = (streamCallback, requestData, values) => {
	if (streamCallback) {
		const req = {...requestData, ...values}
		fetch(config.MPDF_API, {
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(req)
		})
			.then((response) => response.blob())
			.then((blob) => {
				let FileSaver = require("file-saver") // eslint-disable-line no-undef
				FileSaver.saveAs(
					blob,
					req.customFileName ? `${req.customFileName}.pdf` : "print.pdf"
				)
				streamCallback(blob)
			})
	} else {
		requestData["values"] = values
		openWindowWithPost(config.MPDF_API, requestData)
	}
}

export const openWindowWithPost = (url, data) => {
	let form = document.createElement("form")
	form.target = "_blank"
	form.method = "POST"
	form.action = url
	form.style.display = "none"

	for (let key in data) {
		const value = data[key]

		if (isSafe(value) && typeof value == "object") {
			for (let k in value) {
				let input = createHiddenInput(k, value[k])
				form.appendChild(input)
			}
		} else {
			let input = createHiddenInput(key, value)
			form.appendChild(input)
		}
	}
	document.body.appendChild(form)
	form.submit()
	document.body.removeChild(form)
}

export const createHiddenInput = (key, value) => {
	let input = document.createElement("input")
	input.type = "hidden"
	input.name = key
	input.value = value
	return input
}

export const getPerson = () => {
	const user = getUser()

	if (isSafe(user.person)) {
		return user.person
	}

	return null
}

export const getPersonId = () => {
	const user = getUser()

	if (isSafe(user.person)) {
		return user.person._id
	}

	return null
}

export const logout = async () => {
	GlobalStore.loading(true, "Logout in progress...")

	try {
		await api.logout().call()

		StorageBase.deleteByKey(USER_DATA)

		GlobalStore.loading(false)

		RouterStore.push("/login")
	} catch (err) {
		logger(err)
	}
}

export const echo = () => {
	api.getEcho().call()
}

export const useInterval = (callback, delay) => {
	const savedCallback = useRef()

	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		function tick() {
			savedCallback.current()
		}
		if (delay !== null) {
			let id = setInterval(tick, delay)
			return () => clearInterval(id)
		}
	}, [delay])
}

export const mergeObj = (target, source) => {
	for (const key of Object.keys(source)) {
		if (source[key] instanceof Object && key in target) Object.assign(source[key], mergeObj(target[key], source[key]))
	}
	Object.assign(target || {}, source)
	return target
}

export const useMergeState = (initialState) => {
	const [state, setState] = useState(initialState)
	const setMergedState = (newState) => setState((prevState) => mergeObj(prevState, newState))
	return [state, setMergedState]
}

// Vráti objekt s informáciami o poskytovateľovi
export const getUserCompanyInfo = () => {
	const company = getUser().company
	return company ? {...company, id: company._id} : null
}

// Vráti prava uzivatela
// RO - read only
export const getUserAccessRights = () => {
	const user = getUser()

	if (isSafe(user.sec_access) && isSafe(user.sec_access.roles)) {
		if (user.sec_access.roles.find((row) => row.name == "INN:VERIFIED-STAFF")) {
			return "FULL"
		} else {
			return "RO"
		}
	} else {
		return "RO"
	}
}

export const getFieldIdentifier = (field, additional = "") => {
	let customClassName = additional
	if (field) {
		const fieldName = field.name || field.key
		customClassName = customClassName === "" ? fieldName : `${customClassName} ${fieldName}`
	}
	return customClassName
}

export const exportToExcel = (req, action) => {
	if (isEmpty(req.row_count_show)) {
		req.row_count_show = 100000
	}

	if (isEmpty(req.row_offset)) {
		req.row_offset = 1
	}

	if (isEmpty(req.row_count_full)) {
		req.row_count_full = 100000
	}

	new ApiCall("/object/export", "POST", req)
		.call()
		.then((res) => {
			if (res.ok == true) {
				let fileName = isSafe(res.headers.get("content-disposition"))
					? res.headers.get("content-disposition").split("filename=")[1]
					: "export"
				res.blob().then((blob) => {
					let FileSaver = require("file-saver") // eslint-disable-line no-undef
					FileSaver.saveAs(blob, fileName)
				})
				GlobalStore.loading(false)
			}
			if (typeof action === "function") {
				action()
			}
		})
		.catch(() => {
			GlobalStore.loading(false)
		})
}

export const getPriceFormat = (price, digits = null) => {
	return price.toLocaleString("sk-SK", {
		minimumFractionDigits: digits ? digits : 2,
		maximumFractionDigits: digits ? digits : 2
	})
}

// Vráti zoznam obmedzeni na ktore vyzvy ziadatel nemoze podat ziadost
export const getUserCallRestriction = () => {
	const user = getUser()
	if (isSafe(user.call_restriction) && Array.isArray(user.call_restriction)) {
		return user.call_restriction.map((row) => row.call_type_code)
	} else return []
}
